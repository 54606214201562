import {checkMandatoryFields, checkedCitiesOver, checkFreewordLengthOver} from '../../src/muitiple-selective-checkbox';

export function submitButtonOnClickSubmit(submitButtonEl, hiddenEl, hiddenValue, submitEl) {
  $(submitButtonEl).on('click', (e) => {
    e.preventDefault();

    // 必須項目が空白または市区町村が制限以上またはフリーワードの文字数が制限以上の場合submitしない
    var hasError = checkMandatoryFields(submitButtonEl) || checkedCitiesOver() || checkFreewordLengthOver();
    if(!hasError){
      $(hiddenEl).val(hiddenValue);
      $(submitEl).submit()
    }
  });
}
