import 'select2/dist/css/select2.css'
import 'select2'
import 'select2/dist/js/i18n/ja'

export function selectMultipleOptions(el){
  $(el).select2({
    language: "ja"
  })
}

