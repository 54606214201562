import {selectMultipleOptions} from '../modules/select_multiple_options'
import {selectOnChangeSubmit} from '../modules/select_on_change_submit'
import {linkOnClickSubmit} from '../modules/link_on_click_submit'
import {submitButtonOnClickSubmit} from '../modules/submit_button_on_click_submit'
import {deleteParameterFromUrl} from "../modules/delete_parameter_from_url";
import {selectOnChangeLink} from "../modules/select_on_change_link";

$(() => {
  // urlからfrom_submit_buttonを削除してリロードしたときにsearch_historyが保存されないようにする
  deleteParameterFromUrl(location, 'from_submit_button')
  // urlからsave_search_conditionを削除する
  deleteParameterFromUrl(location, 'save_search_condition')
  // urlからfrom_lpを削除する
  deleteParameterFromUrl(location, 'from_lp')

  // urlからemail_haishin_idを削除する
  deleteParameterFromUrl(location, 'email_haishin_id')

  // urlからstudy_search_submitを削除する
  deleteParameterFromUrl(location, 'study_search_submit')

  // select2 js本番を適用した後に外してOK
  selectMultipleOptions('.js-grouped_options_for_select')
  selectMultipleOptions('.js-options_for_select')

  // select box で order を選んだときにhidden valueを埋め込んでsubmit
  selectOnChangeSubmit(".js-order_subsidy_type_select", ".js-order_subsidy_type_hidden_value",".js-order_submit_form")
  selectOnChangeSubmit(".js-order_loan_type_select", ".js-order_loan_type_hidden_value",".js-order_submit_form")
  selectOnChangeSubmit(".js-detail_order_subsidy_type_select", ".js-order_subsidy_type_hidden_value",".js-order_detail_submit_form")
  selectOnChangeSubmit(".js-detail_order_loan_type_select", ".js-order_loan_type_hidden_value",".js-order_detail_submit_form")

  // タブを押したときにhidden valueを埋め込んでsubmit
  linkOnClickSubmit(".js-subsidy_type_group_link", "data-js-group-value", ".js-group_hidden_value",".js-group_submit_form")
  linkOnClickSubmit(".js-loan_type_group_link", "data-js-group-value", ".js-group_hidden_value",".js-group_submit_form")
  linkOnClickSubmit(".js-detail_subsidy_type_group_link", "data-js-group-value", ".js-group_hidden_value",".js-group_detail_submit_form")
  linkOnClickSubmit(".js-detail_loan_type_group_link", "data-js-group-value", ".js-group_hidden_value",".js-group_detail_submit_form")

  // submit buttonを押したときにfrom_submit_button をhidden valueを埋め込んでsubmit
  submitButtonOnClickSubmit(".js-search_submit_button", ".js-search_submit_button_hidden_value", "true",".js-search_submit_button_submit_form")
  submitButtonOnClickSubmit(".js-detail_search_submit_button", ".js-detail_search_submit_button_hidden_value", "true",".js-detail_search_submit_button_submit_form")
  submitButtonOnClickSubmit(".js-search_submit_button_lp_1", ".js-search_submit_button_hidden_value_lp_1", "true",".js-search_submit_button_submit_form_lp_1")
  submitButtonOnClickSubmit(".js-search_submit_button_lp_2", ".js-search_submit_button_hidden_value_lp_2", "true",".js-search_submit_button_submit_form_lp_2")

  // 表示形式変更ボタンを押したときにhidden valueを埋め込んでsubmit
  linkOnClickSubmit(".js-card_view_link", "data-js-tabular-value", ".js-tabuler_hidden_value",".js-group_submit_form")
  linkOnClickSubmit(".js-tabuler_view_link", "data-js-tabular-value", ".js-tabuler_hidden_value",".js-group_submit_form")
  linkOnClickSubmit(".js-detail_card_view_link", "data-js-tabular-value", ".js-tabuler_hidden_value",".js-group_detail_submit_form")
  linkOnClickSubmit(".js-detail_tabuler_view_link", "data-js-tabular-value", ".js-tabuler_hidden_value",".js-group_detail_submit_form")

  // テーブル形式表示のソート切替
  linkOnClickSubmit(".js-order_tabuler", "data-js-tablur-order-value", ".js-order_loan_type_hidden_value",".js-order_submit_form")
  linkOnClickSubmit(".js-detail_order_tabuler", "data-js-tablur-order-value", ".js-order_loan_type_hidden_value",".js-order_detail_submit_form")

  // スマホ用のページネーションのセレクトボックスを変更した時に、ページ遷移を実行する
  selectOnChangeLink("#main-smp-pagination-select-box");
});
